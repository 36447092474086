import React from 'react'
import { graphql } from 'gatsby'

import { Mix as MixTemplateComponent } from '../components/templates'

const Mix: React.FC<Props> = ({ data, location }) => {
  return (
    <MixTemplateComponent
      artist={data.mix.artist}
      catNumber={data.mix.catNumber}
      date={data.mix.date}
      gatsbyImageData={data.mix.image?.gatsbyImageData}
      embed={data.mix?.embedCode?.embedCode}
      content={data.mix?.content?.content}
      location={location}
    />
  )
}

export default Mix

export const query = graphql`
  query MixQuery($catNumber: Float!) {
    mix: contentfulButterMix(catNumber: { eq: $catNumber }) {
      catNumber
      artist
      content {
        content
      }
      date
      embedCode {
        embedCode
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 512)
      }
    }
  }
`

interface Props {
  data: {
    mix: {
      catNumber: number
      artist: string
      content?: {
        content?: string
      }
      date?: string
      embedCode?: {
        embedCode?: string
      }
      image?: {
        gatsbyImageData?: any
      }
    }
  }
  location: any
}
